.Footer {
  display: flex;
  padding-bottom: 65px;
}

.Footer__block {
  flex: 1;
}

.Footer__companyInfo {
  margin-top: 24px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  a {
    color: #ffffff;
    font-weight: 500;
    margin-left: 4px;
  }
}

.Footer__block__title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}

.Footer__block__items {
  margin-top: 48px;
  a {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    line-height: 24px;
    margin-bottom: 12px;
    letter-spacing: -0.32px;
    .Icon {
      margin-left: 8px;
    }
  }
}

.Footer__block__info {
  margin-top: 39px;
}

.Footer__block__info__item {
  margin-bottom: 24px;
}

.Footer__block__info__title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
  margin-bottom: 4px;
}

.Footer__block__info__value {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
}

@media (max-width: 1050px) {
  .Footer {
    flex-wrap: wrap;
  }
  .Footer__block.logo {
    width: 100%;
    order: 4;
    flex: auto;
  }
}

@media (max-width: 650px) {
  .Footer {
    flex-wrap: wrap;
  }
  .Footer__block {
    flex: auto;
    margin-top: 50px;
    width: 100%;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .Footer__block.logo {
    order: unset;
  }
  .Footer__block__info,
  .Footer__block__items {
    margin-top: 24px;
  }
}
