.Icon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: block;
  &.small {
    width: 16px;
    height: 16px;
  }
}

.Icon.contacts {
  background-image: url("../../../../public/images/icons/contacts.svg");
}

.Icon.crunchbase {
  background-image: url("../../../../public/images/icons/crunchbase.svg");
}

.Icon.enterprise {
  background-image: url("../../../../public/images/icons/enterprise.svg");
}

.Icon.github-2 {
  background-image: url("../../../../public/images/icons/github-2.svg");
}

.Icon.github {
  background-image: url("../../../../public/images/icons/github.svg");
}

.Icon.industry {
  background-image: url("../../../../public/images/icons/industry.svg");
}

.Icon.position {
  background-image: url("../../../../public/images/icons/position.svg");
}

.Icon.success {
  background-image: url("../../../../public/images/icons/success.svg");
}

.Icon.user {
  background-image: url("../../../../public/images/icons/user.svg");
}

.Icon.users {
  background-image: url("../../../../public/images/icons/users.svg");
}

.Icon.arrow-left {
  background-image: url("../../../../public/images/icons/arrow-left.svg");
}

.Icon.basic-plan {
  background-image: url("../../../../public/images/icons/basic-plan.svg");
}

.Icon.enterprise-plan {
  background-image: url("../../../../public/images/icons/enterprise-plan.svg");
}

.Icon.corporation-plan {
  background-image: url("../../../../public/images/icons/corporation-plan.svg");
}

.Icon.community-plan {
  background-image: url("../../../../public/images/icons/community-plan.svg");
}

.Icon.checkbox-true {
  background-image: url("../../../../public/images/icons/checkbox-true.svg");
}

.Icon.checkbox-false {
  background-image: url("../../../../public/images/icons/checkbox-false.svg");
}

.Icon.survey-types {
  background-image: url("../../../../public/images/icons/survey-types.svg");
}

.Icon.survey-star {
  background-image: url("../../../../public/images/icons/survey-star.svg");
}

.Icon.survey-size {
  background-image: url("../../../../public/images/icons/survey-size.svg");
}

.Icon.survey-codebase {
  background-image: url("../../../../public/images/icons/survey-codebase.svg");
}
