.SurveyProgress {
  display: flex;
}

.SurveyProgress__itemContainer {
  display: flex;
  align-items: center;
  &.active {
    .SurveyProgress__item {
      background: #6b2dee;
      border-color: #6b2dee;
    }
    .SurveyProgress__line {
      background: #6b2dee;
    }
  }
  &.completed {
    .SurveyProgress__item {
      background: #26c871;
      border-color: #26c871;
    }
    .SurveyProgress__line {
      background: #26c871;
    }
  }
}

.SurveyProgress__item {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.SurveyProgress__line {
  height: 2px;
  width: 16px;
  background: rgba(255, 255, 255, 0.1);
}
