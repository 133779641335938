.SurveyPage {
  width: 100%;
  height: 100vh;
  display: flex;
}

.SurveyPage__left {
  flex: 1;
  padding: 32px;
  position: relative;
  background-image: url('../../../../public/images/survey-page/background1.png');
  background-repeat: no-repeat;
  background-position: 0px bottom;
  background-size: cover;
  border-right: 1px solid #342e3e;
  background-color: #1a1c22;
}

.SurveyPage__surveyIcon {
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.SurveyPage__title {
  margin-top: 24px;
  color: #fff;
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.88px;
}

.SurveyPage__logo {
  position: absolute;
  bottom: 32px;
  left: 32px;
}

.SurveyPage__right {
  flex: 1;
  padding: 40px 48px;
  position: relative;
}

.SurveyPage__backButton {
  color: rgba(255, 255, 255, 0.79);
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  align-items: center;
  line-height: 24px;
  letter-spacing: -0.16px;
  .Icon {
    margin-right: 4px;
  }
}

.SurveyPage__buttons {
  display: flex;
  column-gap: 8px;
  position: absolute;
  bottom: 40px;
  left: 48px;
}

.SurveyPage__success {
  padding: 32px;
  background-image: url('../../../../public/images/survey-page/background2.png');
  background-repeat: no-repeat;
  background-position: 0px bottom;
  background-size: cover;
  background-color: #1a1c22;
  width: 100%;
  position: relative;
  min-height: 100vh;

  .Icon.success {
    width: 80px;
    height: 80px;
  }
}

.SurveyPage__success__title {
  color: #fff;
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.88px;
  margin-top: 40px;
  margin-bottom: 16px;
}

.SurveyPage__success__description {
  max-width: 506px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #ffffff;
  margin-bottom: 40px;
}

@media (max-width: 900px) {
  .SurveyPage {
    flex-direction: column;
    height: auto;
    min-height: 100vh;
  }
  .SurveyPage__left,
  .SurveyPage__right {
    flex: unset;
    padding: 26px;
  }
  .SurveyPage__logo {
    position: relative;
    display: none;
  }
  .SurveyPage__buttons {
    position: relative;
    bottom: unset;
    margin-top: 20px;
    left: unset;
  }
}

@media (max-width: 600px) {
  .SurveyPage__title {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .SurveyPage__surveyIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }
}
