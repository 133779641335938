.MainPage {
  background: #1f2126;
  width: 100%;
  background-image: url("../../../../public/images/main-page/background1.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.MainPage__bottomBackground {
  background-image: url("../../../../public/images/main-page/background2.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 95vw;
}

.MainPage__content {
  width: 1296px;
  z-index: 2;
}

.MainPage__logoBlock {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.MainPage__mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 63px;
  margin-bottom: 260px;
  height: 70vh;
  max-height: 770px;
}

.MainPage__mainImage {
  height: 530px;
  background-image: url("../../../../public/images/main-page/main.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px;
  aspect-ratio: 1/1;
  min-width: 200px;
  max-width: 530px;
}

.MainPage__mainBlock__title {
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 16px;
}

.MainPage__mainBlock__description {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.48px;
  margin-bottom: 40px;
  color: #9297a6;
}

.MainPage__mainBlock__buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.MainPage__descriptionBlock {
  margin-bottom: 322px;
}

.MainPage__logo2 {
  background-image: url("../../../../public/images/main-page/logo2.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 154px;
  height: 43px;
  background-size: contain;
}

.MainPage__description {
  color: #c8d1e8;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.48px;
  display: flex;
  column-gap: 82px;
  margin-top: 16px;
  div {
    flex: 1;
  }
}

.MainPage__plans {
  width: 100%;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 32px;
}

.MainPage__plans__top {
  display: flex;
  margin-bottom: 26px;
  padding: 0 24px;
}

.MainPage__plans__titles__main {
  color: #fff;
  font-size: 44px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.88px;
  text-align: left;
  margin-bottom: 32px;
}

.MainPage__plans__titles__withIcons {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
  text-align: left;
  flex: 1;
  .Icon {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
  }
}

.MainPage__plans__title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.MainPage__plans__title__price {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.64px;
}

.MainPage__plans__title__price__small {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.MainPage__plans__table {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.02);
  padding: 20px 24px;
}

.MainPage__plans__table__row {
  display: flex;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  margin-bottom: 16px;
  div {
    flex: 1;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.MainPage__plans__table__name {
  color: rgba(255, 255, 255, 0.7);
}

.MainPage__plans__buttons {
  display: flex;
  margin-top: 26px;
  > * {
    flex: 1;
  }
}

.MainPage__plansMobile {
  display: none;
  .Button {
    width: 100%;
  }
}

.MainPage__plansMobile__title {
  color: #fff;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.56px;
  margin-bottom: 24px;
}

.MainPage__plansMobile__block {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: rgba(255, 255, 255, 0.02);
  padding: 20px 24px;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }
}
.MainPage__plansMobile__block__title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
  margin-bottom: 32px;
  .Icon {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
  }
}

.MainPage__plansMobile__block__info {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.MainPage__plansMobile__infoItem__title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.24px;
}

.MainPage__plansMobile__infoItem__value {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.32px;
  .Icon {
    margin-top: 4px;
  }
}

.MainPage__communityPlanBlock {
  margin-top: 32px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(107, 45, 238, 0.1);
  padding: 56px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 300px;
}

.MainPage__communityPlanBlock__left {
  display: flex;
  align-items: center;
  .Icon.community-plan {
    width: 72px;
    height: 72px;
    margin-right: 24px;
  }
}

.MainPage__communityPlanBlock__title {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.MainPage__communityPlanBlock__badge {
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 16px;
  background: #6b2dee;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.64px;
}

@media (max-width: 1320px) {
  .MainPage__content {
    width: 100%;
    padding: 32px;
  }
  .MainPage__logoBlock {
    margin-top: 0;
  }
}

@media (max-width: 1050px) {
  .MainPage__plans,
  .MainPage__plans__titles__main {
    display: none;
  }
  .MainPage__plansMobile {
    display: block;
  }
}

@media (max-width: 765px) {
  .MainPage {
    background-image: url("../../../../public/images/main-page/background1-mobile.png");
  }
  .MainPage__logoBlock {
    margin-top: 30px;
  }
  .MainPage__mainBlock {
    margin-bottom: 108px;
  }
  .MainPage__mainBlock__title,
  .MainPage__mainBlock__description {
    text-align: center;
  }
  .MainPage__description {
    flex-direction: column;
    row-gap: 16px;
  }
  .MainPage__descriptionBlock {
    margin-bottom: 140px;
  }

  .MainPage__bottomBackground {
    display: none;
  }
  .MainPage__communityPlanBlock {
    flex-direction: column;
    align-items: flex-start;
    .MainPage__communityPlanBlock__left {
      margin-bottom: 26px;
      justify-content: space-between;
    }
    .Button {
      width: 100%;
    }
  }
  @media (max-height: 650px) {
    .MainPage__mainImage {
      margin-bottom: 20px;
    }
    .MainPage__mainBlock {
      margin-top: 20px;
    }
  }
}

@media (min-width: 561px) and (max-width: 765px) and (max-height: 630px) {
  .MainPage__mainBlock {
    height: auto;
  }
  .MainPage__mainImage {
    height: 200px;
  }
}

@media (max-width: 560px) {
  .MainPage__content {
    padding: 16px;
  }
  .MainPage__logoBlock {
    margin-top: 30px;
  }
  .MainPage__mainImage {
    min-width: 150px;
    height: 300px;
    background-size: contain;
  }
  .MainPage__mainBlock__title {
    font-size: 28px;
  }
  .MainPage__mainBlock__description {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .MainPage__mainBlock__buttons {
    flex-direction: column;
    width: 100%;
    row-gap: 8px;
    .Button {
      width: 100%;
    }
  }
  .MainPage__description {
    font-size: 20px;
    line-height: 28px;
  }
  @media (max-height: 650px) {
    .MainPage__mainImage {
      margin-bottom: 20px;
    }
    .MainPage__mainBlock {
      margin-top: 20px;
    }
  }
}

@media (max-width: 460px) {
  .MainPage__communityPlanBlock__title {
    font-size: 20px;
  }
  .MainPage__communityPlanBlock__badge {
    font-size: 22px;
  }
}

@media (max-width: 380px) {
  .MainPage__communityPlanBlock__left .Icon.community-plan {
    width: 60px;
    height: 60px;
  }
  .MainPage__communityPlanBlock__title {
    font-size: 16px;
  }
  .MainPage__communityPlanBlock__badge {
    font-size: 16px;
  }
}
