.Button {
  padding: 16px 24px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  line-height: 24px;
  letter-spacing: -0.32px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
  &.primary {
    background: #6b2dee;
  }
  &.secondary {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
  }
  .Icon {
    margin-right: 8px;
  }
  &.disabled {
    background: gray;
  }
}
