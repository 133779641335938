@import "./components/button.scss";
@import "./components/icons.scss";
@import "./components/footer.scss";
@import "./components/survey-progress.scss";
@import "./components/survey-form.scss";

@import "./pages/main.scss";
@import "./pages/survey.scss";

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-VariableFont_wght.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background: #1f2126;
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
}

html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Logo {
  background-image: url("../../../public/images/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 220px;
  height: 43px;
  background-size: contain;
}
